html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  line-height: inherit;
  width: 2em;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fab.fa-pull-left, .fal.fa-pull-left, .far.fa-pull-left, .fas.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fab.fa-pull-right, .fal.fa-pull-right, .far.fa-pull-right, .fas.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical, .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270 {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-wash:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-user:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-innosoft:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-house:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-arrows:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.e4f790e7.eot");
  src: url("fa-brands-400.e4f790e7.eot#iefix") format("embedded-opentype"), url("fa-brands-400.0b52c614.woff2") format("woff2"), url("fa-brands-400.a0b02584.woff") format("woff"), url("fa-brands-400.81da6092.ttf") format("truetype"), url("fa-brands-400.068b1299.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.530b4f61.eot");
  src: url("fa-regular-400.530b4f61.eot#iefix") format("embedded-opentype"), url("fa-regular-400.5bedcec6.woff2") format("woff2"), url("fa-regular-400.09c79cc5.woff") format("woff"), url("fa-regular-400.963ebff5.ttf") format("truetype"), url("fa-regular-400.a55d9c35.svg#fontawesome") format("svg");
}

.fab, .far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.6b1a037a.eot");
  src: url("fa-solid-900.6b1a037a.eot#iefix") format("embedded-opentype"), url("fa-solid-900.6002b354.woff2") format("woff2"), url("fa-solid-900.80005ece.woff") format("woff"), url("fa-solid-900.67ecc626.ttf") format("truetype"), url("fa-solid-900.45f46c3e.svg#fontawesome") format("svg");
}

.fa, .far, .fas {
  font-family: "Font Awesome 5 Free";
}

.fa, .fas {
  font-weight: 900;
}

:root {
  interpolate-size: allow-keywords;
}

* {
  box-sizing: border-box;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

:focus-visible {
  outline: none;
}

:after, :before {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100dvh;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #000;
  background-color: #fff;
  height: 100%;
  font-family: museo-sans, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  overflow-x: hidden;
}

::selection {
  text-shadow: .05em .05em .01em #0006;
  color: #fff;
  background-color: #008bdc99;
}

section {
  padding: 3rem;
}

h1, h2, h3, h4 {
  font-weight: 700;
}

h1 small, h2 small, h3 small, h4 small {
  font-weight: 300;
}

h1 {
  margin-bottom: 1.5rem;
  font-size: 29px;
}

h2 {
  margin-bottom: 1.5rem;
  font-size: 20px;
}

h3 {
  margin-bottom: 1.25rem;
  font-size: 18px;
}

h4 {
  font-size: 16px;
  font-weight: 700;
}

h4 .fas {
  font-size: 14px;
}

.sidemenu h2 {
  margin-bottom: 0;
  font-size: 15px;
}

.sidemenu h3 {
  margin-bottom: 0;
  font-size: 14px;
}

.sidemenu .func-dropdown-checkbox-parent-0 {
  height: 58px;
}

.sidemenu .func-dropdown-checkbox-parent-1 {
  height: 48px;
}

small {
  font-size: 14px;
}

hr {
  height: 0;
}

a {
  color: #008bdc;
}

li a, a.sortable, a.asc, a.desc {
  color: #000;
  text-decoration: none;
}

li a:after, a.sortable:after, a.asc:after, a.desc:after {
  content: "";
  position: absolute;
  inset: 0;
}

ul {
  padding-left: 1.5rem;
}

ul li {
  line-height: 1.5em;
  position: relative;
}

ul.bullet-list {
  margin-left: 1rem;
  padding-left: 0;
  list-style-type: disc;
  list-style-position: inside;
}

.side-menu:has(.menu-checkbox:checked) li, .side-menu:has(.menu-checkbox:checked) hr + div {
  position: relative;
}

.side-menu:has(.menu-checkbox:checked) li:before, .side-menu:has(.menu-checkbox:checked) hr + div:before {
  content: "";
  transform-origin: 0;
  z-index: 0;
  background-color: #007bc3;
  width: 100%;
  height: 100%;
  max-height: 64px;
  transition: transform .21s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
}

.side-menu:has(.menu-checkbox:checked) li span, .side-menu:has(.menu-checkbox:checked) hr + div span {
  z-index: 1;
  position: relative;
}

.side-menu:has(.menu-checkbox:checked) label {
  position: relative;
}

.side-menu:has(.menu-checkbox:checked) hr + div:hover:before, .side-menu:has(.menu-checkbox:checked) li:hover:before {
  transform: scaleX(1);
}

.side-menu:has(.menu-checkbox:checked) hr + div:hover:has(li:hover):before {
  transform: scaleX(0);
}

.btn, button {
  cursor: pointer;
  white-space: nowrap;
  border: none;
  border-radius: 9001px;
  padding: .75rem 1rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none;
  display: inline-block;
}

.btn-lg {
  padding: 1rem 1.5rem;
  font-size: 16px;
}

.btn-sm {
  border-radius: 15px;
  padding: .25rem .75rem;
}

.circle-btn {
  width: 24px;
  height: 24px;
  padding: 0 !important;
}

select, textarea, input {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  padding: 1rem 1.25rem;
  font-family: museo-sans, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

select[type="submit"], textarea[type="submit"], input[type="submit"], select[type="radio"], textarea[type="radio"], input[type="radio"] {
  width: auto;
}

select[type="date"], textarea[type="date"], input[type="date"] {
  border-color: #000;
  padding: .75rem;
}

select[type="text"]:disabled, textarea[type="text"]:disabled, input[type="text"]:disabled {
  opacity: .2;
}

.login-menu select, .login-menu textarea, .login-menu input {
  margin: .75rem 0 1.5rem;
}

select.custom-radio, select.custom-checkbox, textarea.custom-radio, textarea.custom-checkbox, input.custom-radio, input.custom-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

select:focus, textarea:focus, input:focus {
  border-color: #008bdc;
}

select::placeholder, textarea::placeholder, input::placeholder {
  font-family: museo-sans, sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
}

.floating-label-wrapper select + .floating-label, .floating-label-wrapper textarea + .floating-label, .floating-label-wrapper input + .floating-label {
  pointer-events: none;
  color: #00000080;
  background: linear-gradient(#0000 50%, #fff 50%);
  transition-duration: .3s;
  position: absolute;
  top: 50%;
  left: 1.25rem;
  transform: translateY(-50%)skew(-15deg);
}

.floating-label-wrapper select:focus ~ .floating-label, .floating-label-wrapper textarea:focus ~ .floating-label, .floating-label-wrapper input:focus ~ .floating-label {
  color: #008bdc;
}

.floating-label-wrapper select:focus ~ .floating-label, .floating-label-wrapper select:not(:placeholder-shown) ~ .floating-label, .floating-label-wrapper textarea:focus ~ .floating-label, .floating-label-wrapper textarea:not(:placeholder-shown) ~ .floating-label, .floating-label-wrapper input:focus ~ .floating-label, .floating-label-wrapper input:not(:placeholder-shown) ~ .floating-label {
  padding: 0 .25rem;
  font-size: 12px;
  top: 0;
  left: calc(.75rem + 1px);
  transform: translateY(-50%);
}

.floating-label-wrapper select[disabled="disabled"], .floating-label-wrapper textarea[disabled="disabled"], .floating-label-wrapper input[disabled="disabled"] {
  background: #f5f5f5;
}

.floating-label-wrapper select[disabled="disabled"] + .floating-label, .floating-label-wrapper textarea[disabled="disabled"] + .floating-label, .floating-label-wrapper input[disabled="disabled"] + .floating-label {
  background: linear-gradient(#0000 50%, #f5f5f5 50%);
}

.custom-slide-btn {
  background: #f5f5f5;
  border-radius: 9001px;
  width: 106px;
  height: 32px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.custom-slide-btn input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.custom-slide-btn .circle {
  background: #d9d9d9;
  border-radius: 9001px;
  width: 25px;
  height: 25px;
  transition: all .21s;
  position: absolute;
  top: 3px;
  left: 6px;
}

.custom-slide-btn .text {
  text-align: center;
  color: #000;
  width: 23px;
  height: 24px;
  position: absolute;
  top: 3px;
  right: 30px;
}

.custom-slide-btn .text:before {
  content: attr(data-text-false);
}

.custom-slide-btn:has(input:checked) {
  background: #008bdc;
}

.custom-slide-btn:has(input:checked) .text {
  right: 50px;
}

.custom-slide-btn:has(input:checked) .text:before {
  content: attr(data-text-true);
  color: #fff;
  font-weight: 700;
}

.custom-slide-btn:has(input:checked) .circle {
  background: #fff;
  left: 77px;
}

.floating-label-wrapper {
  position: relative;
}

.create-survey-form > .language-input {
  margin-bottom: 1.5rem;
}

.draggable-input-wrapper .fas.fa-arrows-alt {
  cursor: grab;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
}

.draggable-input-wrapper .floating-label-wrapper {
  margin: 0;
}

textarea {
  resize: vertical;
}

label {
  cursor: pointer;
}

label:has(.custom-checkbox), label:has(.custom-radio) {
  align-items: center;
  padding-left: calc(1rem + 22px);
  display: flex;
  position: relative;
}

label:has(.custom-checkbox):before, label:has(.custom-radio):before {
  content: "";
  text-align: center;
  background-color: #fff;
  border: 1px solid #b7b7b7;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

label:has(.custom-checkbox):has(:disabled), label:has(.custom-radio):has(:disabled) {
  opacity: .4;
  filter: grayscale();
}

.can-be-enabled label:has(.custom-checkbox):has(:disabled):hover, .can-be-enabled label:has(.custom-radio):has(:disabled):hover {
  opacity: .7;
  filter: grayscale(.3);
}

label:has(.custom-checkbox):before {
  border-radius: 5px;
}

label:has(.custom-radio) {
  height: 24px;
}

label:has(.custom-radio):has(.custom-radio.radio-only) {
  padding-left: 24px;
}

label:has(.custom-radio):after, label:has(.custom-radio):before {
  border-radius: 9001px;
}

label:has(.custom-checkbox:checked):after, label:has(.custom-radio:checked):after {
  position: absolute;
  top: 12px;
  left: 12px;
  transform: translate(-50%, -50%);
}

label:has(.custom-checkbox:checked):before {
  background-color: #008bdc;
  border-color: #008bdc;
}

label:has(.custom-checkbox:checked):after {
  content: "";
  color: #fff;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 700;
  position: absolute;
}

label:has(.custom-radio:checked):before {
  background-color: #fff;
  border-color: #363636;
}

label:has(.custom-radio:checked):after {
  content: "";
  text-align: center;
  color: #008bdc;
  background-color: #008bdc;
  width: 12px;
  height: 12px;
  position: absolute;
}

.switch-label .switch-checkbox {
  cursor: pointer;
  width: 0;
  height: 0;
  position: relative;
}

.switch-label .switch-checkbox:before {
  content: "";
  background-color: #d9d9d9;
  border-radius: 20px;
  width: 37px;
  height: 20px;
  transition: all .4s;
  position: absolute;
  top: -5px;
  right: 0;
}

.switch-label .switch-checkbox:after {
  content: "";
  background-color: #fff;
  border-radius: 9001px;
  width: 16px;
  height: 16px;
  transition: all .4s;
  position: absolute;
  top: -3px;
  right: 18px;
}

.switch-label .switch-checkbox:checked:before {
  background-color: #008bdc;
}

.switch-label .switch-checkbox:checked:after {
  right: 2px;
}

b, strong {
  font-weight: 700;
}

select {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1rem 1.25rem;
  font-size: 16px;
}

select.select-sm {
  border-color: #b7b7b7;
  padding: .75rem 16px;
  font-size: 14px;
}

.overlay-primary {
  background-size: cover;
}

.overlay-primary:before {
  content: "";
  opacity: .8;
  background-color: #008bdc;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.two-fa-input {
  letter-spacing: 45px;
  width: 370px;
  font-family: monospace;
}

table {
  border-spacing: 0;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 15px;
  width: 100%;
}

table td:has(.custom-radio) {
  align-items: center;
}

table.table-striped tr:nth-child(2n) td, table.table-striped th {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

table.table-conditional-lines tbody tr td:nth-child(2), table.table-conditional-lines tbody tr td:nth-child(3), table.table-conditional-lines tbody tr td:nth-child(4) {
  border-left: 1px solid #d9d9d9;
}

table.table-conditional-lines tbody tr td:nth-child(4) {
  border-right: 1px solid #d9d9d9;
}

table.table-primary-line tr td:nth-child(4), table.table-primary-line tr th:nth-child(4) {
  border-right: 2px solid #008bdc !important;
}

table.table-primary-line tbody tr.table-conditional-footer {
  color: #fff;
  background-color: #f5f5f5;
}

table.table-primary-line tbody tr.table-conditional-footer td {
  text-align: center;
  background-color: #008bdc;
  border: #008bdc;
}

table.table-primary-line tbody tr.table-conditional-footer td:first-of-type {
  text-align: left;
  border-bottom-left-radius: 15px;
}

table.table-primary-line tbody tr.table-conditional-footer td:last-of-type {
  border-bottom-right-radius: 15px;
}

table tr.conditional-history-row td {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

table .hidden-row th, table .hidden-row td {
  border: none;
  padding: 1rem 0 0;
}

table .hidden-row th[data-label-text], table .hidden-row td[data-label-text], table .hidden-row th[data-label-text] + td, table .hidden-row th[data-label-text] th, table .hidden-row td[data-label-text] + td, table .hidden-row td[data-label-text] th {
  border-left: 1px solid #d9d9d9;
}

table .hidden-row th[data-label-text] + td.border-primary, table .hidden-row th[data-label-text] th.border-primary, table .hidden-row td[data-label-text] + td.border-primary, table .hidden-row td[data-label-text] th.border-primary {
  border-width: 2px;
}

table.table-angled-headers {
  margin-top: 9.5rem;
}

table.table-angled-headers th, table.table-angled-headers td {
  position: relative;
}

table.table-angled-headers th[data-label-text]:before, table.table-angled-headers td[data-label-text]:before {
  content: attr(data-label-text);
  transform-origin: -13px 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 12rem;
  padding-left: 0;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: rotate(-50deg);
}

table.table-angled-headers th[data-label-text]:after, table.table-angled-headers td[data-label-text]:after {
  content: "";
  transform-origin: 100% 100%;
  border-right: 2px solid #d9d9d9;
  width: calc(100% + 2px);
  height: 8rem;
  position: absolute;
  bottom: 100%;
  left: -1px;
  transform: skew(-40deg);
}

table.table-angled-headers th[data-label-text]:nth-of-type(2):after, table.table-angled-headers td[data-label-text]:nth-of-type(2):after {
  border-left: 2px solid #d9d9d9;
}

table.table-angled-headers th[data-label-text].data-label-primary:after, table.table-angled-headers td[data-label-text].data-label-primary:after {
  border-right: 3px solid #008bdc;
  width: calc(100% + 4px);
}

table.table-angled-headers th {
  text-align: center;
}

td {
  vertical-align: middle;
  border: none;
  padding: 1rem 1.5rem;
}

td a {
  color: #fff;
  text-decoration: none;
}

th {
  text-align: left;
  vertical-align: middle;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: 17px;
  position: relative;
}

th .fas {
  pointer-events: none;
}

th + th {
  border-left: 1px solid #d9d9d9;
}

th:first-child {
  border-top-left-radius: 10px;
}

th:last-child {
  border-top-right-radius: 10px;
}

.tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  color: #fff;
  text-align: center;
  opacity: 0;
  background-color: #008bdc;
  border-radius: 5px;
  width: 28rem;
  padding: .75rem;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  transition: opacity .21s;
  position: absolute;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container .tooltip-text:before, .tooltip-container .tooltip-text:after {
  content: "";
  border-top: 10px solid #008bdc;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.menu-checkbox, .func-drowpdown-checkbox {
  display: none;
}

.menu-checkbox-parent {
  min-width: 57px;
  max-width: 57px;
  max-height: 100dvh;
  transition: min-width .21s ease-in-out;
  position: sticky;
  top: 0;
  overflow-x: hidden;
}

.menu-checkbox-parent .show-if-checked {
  display: none;
}

.menu-checkbox-parent .fas + span {
  transition: margin-left .21s ease-in-out;
  margin-left: 3rem !important;
}

.menu-checkbox-parent li {
  transition: padding-left .21s ease-in-out;
  padding-left: 9rem !important;
}

.menu-checkbox-parent:has(.menu-checkbox:checked) {
  min-width: 281px;
}

.menu-checkbox-parent:has(.menu-checkbox:checked) .show-if-checked {
  display: block;
}

.menu-checkbox-parent:has(.menu-checkbox:checked) .hide-if-checked {
  display: none;
}

.menu-checkbox-parent:has(.menu-checkbox:checked) .fas + span {
  margin-left: 1rem !important;
}

.menu-checkbox-parent:has(.menu-checkbox:checked) li {
  padding-left: 2rem !important;
}

.func-visibility-checkbox-parent {
  align-content: center;
  padding: 0;
}

.func-visibility-checkbox-parent .menu-checkbox, .func-visibility-checkbox-parent .func-tooltip-checkbox, .func-visibility-checkbox-parent .func-title-input-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.func-visibility-checkbox-parent:after, .func-visibility-checkbox-parent:before {
  display: none;
}

.func-dropdown-checkbox-parent-0 {
  height: 64px;
  transition: height .21s ease-in-out;
  overflow: hidden;
}

.draggable-container .func-dropdown-checkbox-parent-0 {
  height: 21px;
}

.func-dropdown-checkbox-parent-0.chapter-wrapper, .func-dropdown-checkbox-parent-0.section-wrapper {
  height: 88px;
}

.func-dropdown-checkbox-parent-0.question-dropdown-parent {
  height: 91px;
}

.func-dropdown-checkbox-parent-0 .dropdown-arrow-transform-0 {
  transition: transform .21s ease-in-out;
  transform: rotate(0);
}

.func-dropdown-checkbox-parent-0:has(.func-dropdown-checkbox-0:checked) {
  height: auto;
}

.func-dropdown-checkbox-parent-0:has(.func-dropdown-checkbox-0:checked) .dropdown-arrow-transform-0 {
  transform: rotate(180deg);
}

.func-dropdown-checkbox-parent-0 .func-visibility-checkbox-parent .func-dropdown-checkbox-0 {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.func-dropdown-checkbox-parent-1 {
  height: 64px;
  transition: height .21s ease-in-out;
  overflow: hidden;
}

.draggable-container .func-dropdown-checkbox-parent-1 {
  height: 21px;
}

.func-dropdown-checkbox-parent-1.chapter-wrapper, .func-dropdown-checkbox-parent-1.section-wrapper {
  height: 88px;
}

.func-dropdown-checkbox-parent-1.question-dropdown-parent {
  height: 91px;
}

.func-dropdown-checkbox-parent-1 .dropdown-arrow-transform-1 {
  transition: transform .21s ease-in-out;
  transform: rotate(0);
}

.func-dropdown-checkbox-parent-1:has(.func-dropdown-checkbox-1:checked) {
  height: auto;
}

.func-dropdown-checkbox-parent-1:has(.func-dropdown-checkbox-1:checked) .dropdown-arrow-transform-1 {
  transform: rotate(180deg);
}

.func-dropdown-checkbox-parent-1 .func-visibility-checkbox-parent .func-dropdown-checkbox-1 {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.func-dropdown-checkbox-parent-2 {
  height: 64px;
  transition: height .21s ease-in-out;
  overflow: hidden;
}

.draggable-container .func-dropdown-checkbox-parent-2 {
  height: 21px;
}

.func-dropdown-checkbox-parent-2.chapter-wrapper, .func-dropdown-checkbox-parent-2.section-wrapper {
  height: 88px;
}

.func-dropdown-checkbox-parent-2.question-dropdown-parent {
  height: 91px;
}

.func-dropdown-checkbox-parent-2 .dropdown-arrow-transform-2 {
  transition: transform .21s ease-in-out;
  transform: rotate(0);
}

.func-dropdown-checkbox-parent-2:has(.func-dropdown-checkbox-2:checked) {
  height: auto;
}

.func-dropdown-checkbox-parent-2:has(.func-dropdown-checkbox-2:checked) .dropdown-arrow-transform-2 {
  transform: rotate(180deg);
}

.func-dropdown-checkbox-parent-2 .func-visibility-checkbox-parent .func-dropdown-checkbox-2 {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.func-tooltip-checkbox-parent {
  position: relative;
}

.func-tooltip-checkbox-parent .tooltip {
  pointer-events: none;
  opacity: 0;
  z-index: 9001;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  min-width: 100%;
  padding: .75rem;
  transition-duration: .21s;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 .5rem 1rem #d9d9d9;
}

.func-tooltip-checkbox-parent:has(.func-tooltip-checkbox:checked) .tooltip {
  pointer-events: all;
  opacity: 1;
}

.checkbox-rows-parent {
  justify-content: space-between;
  display: flex;
}

.js-check-radio-table .menu-items a {
  color: #fff;
  text-decoration: none;
}

.func-sortable-toggle + .func-togglable-sortable-container .fa-arrows-alt, .func-sortable-toggle:has(.switch-checkbox:checked) + .func-togglable-sortable-container .func-toggle-copy-button, .func-sortable-toggle:has(.switch-checkbox:checked) + .func-togglable-sortable-container .func-toggle-delete-button {
  display: none;
}

.func-sortable-toggle:has(.switch-checkbox:checked) + .func-togglable-sortable-container .func-toggle-handle {
  cursor: grab;
  display: block;
}

.language-input {
  min-width: 250px;
  position: relative;
}

.language-input input[type="text"] {
  display: none;
}

.language-input .language-select {
  cursor: pointer;
  z-index: 1;
  background: linear-gradient(#0000 39%, #fff 39%);
  padding: 0 8px;
  position: absolute;
  top: 0;
  right: 10px;
  transform: translateY(-50%);
}

.language-input .language-select input[type="radio"] {
  display: none;
}

.language-input .language-select label {
  cursor: pointer;
}

.language-input .language-select label img {
  opacity: .6;
  filter: grayscale(.5);
  border: 1px solid #d9d9d9;
}

.language-input .language-select label:hover img, .language-input .language-select label:has(input[type="radio"]:checked) img {
  opacity: 1;
  filter: grayscale(0);
}

.language-input:has(label:first-of-type input[type="radio"]:checked) input[type="text"]:first-of-type, .language-input:has(label:nth-of-type(2) input[type="radio"]:checked) input[type="text"]:nth-of-type(2), .language-input:has(label:nth-of-type(3) input[type="radio"]:checked) input[type="text"]:nth-of-type(3), .language-input:has(label:nth-of-type(4) input[type="radio"]:checked) input[type="text"]:nth-of-type(4), .language-input:has(label:nth-of-type(5) input[type="radio"]:checked) input[type="text"]:nth-of-type(5), .language-input:has(label:nth-of-type(6) input[type="radio"]:checked) input[type="text"]:nth-of-type(6), .language-input:has(label:nth-of-type(7) input[type="radio"]:checked) input[type="text"]:nth-of-type(7), .language-input:has(label:nth-of-type(8) input[type="radio"]:checked) input[type="text"]:nth-of-type(8), .language-input:has(label:nth-of-type(9) input[type="radio"]:checked) input[type="text"]:nth-of-type(9), .language-input:has(label:nth-of-type(10) input[type="radio"]:checked) input[type="text"]:nth-of-type(10) {
  display: block;
}

.subquestion-sortable-container {
  margin-left: 2rem;
}

.func-sortable-column-wrapper:has(a.sortable) .fa-sortable-arrows {
  transition: transform .21s ease-in-out;
  transform: rotate(90deg);
}

.func-sortable-column-wrapper:has(a.sortable) .fa-sortable-arrows:before {
  content: "";
}

.func-sortable-column-wrapper:has(a.asc) .fa-sortable-arrows {
  transition: transform .21s ease-in-out;
}

.func-sortable-column-wrapper:has(a.asc) .fa-sortable-arrows:before {
  content: "";
  transition: transform .21s ease-in-out;
}

.func-sortable-column-wrapper:has(a.desc) .fa-sortable-arrows {
  transition: transform .21s ease-in-out;
}

.func-sortable-column-wrapper:has(a.desc) .fa-sortable-arrows:before {
  content: "";
  transition: transform .21s ease-in-out;
}

a > svg.loading path:first-child {
  fill: #008bdc;
  animation: 1.05s infinite loading;
}

@keyframes loading {
  0% {
    fill: #008bdc;
  }

  50% {
    fill: #5cc3ff;
  }

  100% {
    fill: #008bdc;
  }
}

.pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.pagination li {
  display: inline-block;
}

.pagination li:not(:last-child) {
  margin-right: .5rem;
}

.pagination li.disabled {
  color: #949494;
  cursor: not-allowed;
  background-color: #0000;
  border: none;
}

.pagination li .page-link {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: .5rem;
  padding: .7rem 1rem;
  text-decoration: none;
  display: inline-block;
}

.pagination li .page-link:hover {
  color: #fff;
  background-color: #008bdc;
  border-color: #008bdc;
}

.pagination li.active .page-link {
  color: #fff;
  background-color: #008bdc;
  border-color: #008bdc;
  font-weight: 700;
}

.pagination li.disabled .page-link {
  color: #000;
  cursor: not-allowed;
  background-color: #0000;
  border: none;
  font-weight: 700;
}

form:has(.action-bar), section:has(.action-bar) {
  padding-bottom: 8rem;
}

.action-bar {
  z-index: 1000;
  background: #fff;
  border-top: 1px solid #ebebeb;
  width: calc(100% - 57px);
  padding: 1rem 3rem;
  transition: width .21s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
}

body:has(.menu-checkbox:checked) .action-bar {
  width: calc(100% - 281px);
}

.has-sidebar .action-bar {
  width: calc(100% - 357px);
}

body:has(.menu-checkbox:checked) .has-sidebar .action-bar {
  width: calc(100% - 581px);
}

.func-title-input-checkbox-parent {
  height: calc(26px + 2rem);
}

.func-title-input-checkbox-parent .language-input, .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .func-title-input-title {
  display: none;
}

.func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:first-of-type input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(2) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(3) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(4) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(5) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(6) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(7) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(8) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(9) input[type="radio"]:checked), .func-title-input-checkbox-parent:has(.func-title-input-checkbox:checked) .language-input:has(label:nth-of-type(10) input[type="radio"]:checked) {
  display: block;
}

.info-circle {
  text-align: center;
  background-color: #fff;
  border: 1px solid #008bdc;
  border-radius: 9001px;
  width: 26px;
  height: 26px;
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
}

.info-circle[data-value="0"] {
  border-color: #ebebeb;
}

.info-circle[data-value="1"] {
  border-color: #008bdc80;
}

.info-circle[data-value="2"] {
  border-color: #008bdc;
}

.chart-styling .apexcharts-menu-icon {
  display: none !important;
}

.chart-styling .apexcharts-toolbar .apexcharts-menu-icon, .chart-styling .apexcharts-toolbar .apexcharts-pan-icon, .chart-styling .apexcharts-toolbar .apexcharts-reset-icon, .chart-styling .apexcharts-toolbar .apexcharts-selection-icon, .chart-styling .apexcharts-toolbar .apexcharts-toolbar-custom-icon, .chart-styling .apexcharts-toolbar .apexcharts-zoom-icon, .chart-styling .apexcharts-toolbar .apexcharts-zoomin-icon, .chart-styling .apexcharts-toolbar .apexcharts-zoomout-icon {
  width: 44px;
  height: 40px;
  transform: scale(.6);
}

.chart-styling .apexcharts-toolbar .apexcharts-zoomin-icon:hover, .chart-styling .apexcharts-toolbar .apexcharts-zoomout-icon:hover {
  cursor: pointer;
  background-color: #0000;
  border-radius: 9001px;
}

.chart-styling .apexcharts-toolbar .apexcharts-zoomin-icon:hover path + path, .chart-styling .apexcharts-toolbar .apexcharts-zoomout-icon:hover path + path {
  fill: #008bdc;
}

.chart-styling .apexcharts-toolbar svg {
  width: 40px;
  height: 40px;
}

.fixed-bottom-right nav {
  margin: 0;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-width: calc(100vw - 57px - 6rem);
  margin-bottom: 1rem;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.search-input-container {
  position: relative;
}

.search-input-container .search-input {
  padding-right: 4.5rem;
}

.search-input-container .search-action-buttons {
  align-items: center;
  gap: .5rem;
  display: flex;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

.search-input-container .search-action-buttons .search-icon-btn, .search-input-container .search-action-buttons .clear-icon-btn {
  color: #000;
  background: none;
  border: 0;
  padding: .375rem;
  line-height: 1;
  transition: color .2s;
}

.search-input-container .search-action-buttons .search-icon-btn:hover, .search-input-container .search-action-buttons .clear-icon-btn:hover {
  color: #008bdc;
}

.timeline-container {
  padding: 1rem 0;
}

.timeline-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.timeline-line {
  z-index: 0;
  background: #d9d9d9;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 15px;
}

.timeline-item {
  z-index: 1;
  text-align: center;
  flex: 1;
  position: relative;
}

.timeline-item.current-month {
  background: #008bdc1a;
  border-radius: 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: .5rem;
}

.timeline-dot {
  background: #d9d9d9;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 auto;
  position: relative;
}

.timeline-month {
  color: #c7c7c7;
  margin-top: .5rem;
  font-size: 14px;
}

.visit-button {
  color: #fff;
  background: #008bdc;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.visit-button:hover {
  background: #006ba9;
}

.add-visit-wrapper {
  text-align: right;
  margin-top: .75rem;
}

.autocomplete-container {
  width: 300px;
  font-family: Arial, sans-serif;
  position: relative;
}

.suggestions {
  z-index: 10;
  background: #fff;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  position: absolute;
  overflow-y: auto;
}

.suggestion-item:hover {
  background: #f0f0f0;
}

.no-suggestions {
  color: #999;
  padding: 8px;
}

.selected-items {
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  display: flex;
}

.selected-item {
  background: #e0e0e0;
  border-radius: 15px;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  display: flex;
}

.remove-item-btn {
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  font-size: 14px;
}

.autocomplete-suggestions {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  display: none;
  position: absolute;
  overflow-y: auto;
}

.suggestion-item {
  cursor: pointer;
  padding: 8px;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.no-suggestions {
  color: #999;
  text-align: center;
  padding: 8px;
}

.d-flex, .d-flex-list li {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.justify-content-start {
  justify-content: start;
}

.align-items-start {
  align-items: start;
}

.justify-content-end {
  justify-content: end;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center, .d-flex-list li {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-space-between {
  align-items: space-between;
}

.justify-content-around {
  justify-content: around;
}

.align-items-around {
  align-items: around;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1, .d-flex-list li .fas + * {
  flex-grow: 1;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-75 {
  flex-basis: 75%;
}

.d-flex-list li .fas {
  width: 1.25rem;
}

.d-flex-list li .fas + * {
  margin-left: .5rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.left-4 {
  left: 1rem;
}

.align-content-center {
  align-content: center;
}

.position-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.z-index-1000 {
  z-index: 1000;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.m-auto {
  margin: auto !important;
}

.my-auto, .mt-auto {
  margin-top: auto !important;
}

.my-auto, .mb-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.my-0, .mt-0 {
  margin-top: 0 !important;
}

.my-0, .mb-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.my-1, .mt-1 {
  margin-top: .25rem !important;
}

.my-1, .mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.my-2, .mt-2 {
  margin-top: .5rem !important;
}

.my-2, .mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.my-3, .mt-3 {
  margin-top: .75rem !important;
}

.my-3, .mb-3 {
  margin-bottom: .75rem !important;
}

.ml-3, .mx-3 {
  margin-left: .75rem !important;
}

.mr-3, .mx-3 {
  margin-right: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.my-4, .mt-4 {
  margin-top: 1rem !important;
}

.my-4, .mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.my-5, .mt-5 {
  margin-top: 1.25rem !important;
}

.my-5, .mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5, .mx-5 {
  margin-left: 1.25rem !important;
}

.mr-5, .mx-5 {
  margin-right: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.my-6, .mt-6 {
  margin-top: 1.5rem !important;
}

.my-6, .mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6, .mx-6 {
  margin-left: 1.5rem !important;
}

.mr-6, .mx-6 {
  margin-right: 1.5rem !important;
}

.m-7 {
  margin: 2rem !important;
}

.my-7, .mt-7 {
  margin-top: 2rem !important;
}

.my-7, .mb-7 {
  margin-bottom: 2rem !important;
}

.ml-7, .mx-7 {
  margin-left: 2rem !important;
}

.mr-7, .mx-7 {
  margin-right: 2rem !important;
}

.m-8 {
  margin: 3rem !important;
}

.my-8, .mt-8 {
  margin-top: 3rem !important;
}

.my-8, .mb-8 {
  margin-bottom: 3rem !important;
}

.ml-8, .mx-8 {
  margin-left: 3rem !important;
}

.mr-8, .mx-8 {
  margin-right: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.py-auto, .pt-auto {
  padding-top: auto !important;
}

.py-auto, .pb-auto {
  padding-bottom: auto !important;
}

.pl-auto, .px-auto {
  padding-left: auto !important;
}

.pr-auto, .px-auto {
  padding-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.py-0, .pt-0 {
  padding-top: 0 !important;
}

.py-0, .pb-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.py-1, .pt-1 {
  padding-top: .25rem !important;
}

.py-1, .pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.py-2, .pt-2 {
  padding-top: .5rem !important;
}

.py-2, .pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.py-3, .pt-3 {
  padding-top: .75rem !important;
}

.py-3, .pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3, .px-3 {
  padding-left: .75rem !important;
}

.pr-3, .px-3 {
  padding-right: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.py-4, .pt-4 {
  padding-top: 1rem !important;
}

.py-4, .pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4, .px-4 {
  padding-left: 1rem !important;
}

.pr-4, .px-4 {
  padding-right: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.py-5, .pt-5 {
  padding-top: 1.25rem !important;
}

.py-5, .pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5, .px-5 {
  padding-left: 1.25rem !important;
}

.pr-5, .px-5 {
  padding-right: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.py-6, .pt-6 {
  padding-top: 1.5rem !important;
}

.py-6, .pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6, .px-6 {
  padding-left: 1.5rem !important;
}

.pr-6, .px-6 {
  padding-right: 1.5rem !important;
}

.p-7 {
  padding: 2rem !important;
}

.py-7, .pt-7 {
  padding-top: 2rem !important;
}

.py-7, .pb-7 {
  padding-bottom: 2rem !important;
}

.pl-7, .px-7 {
  padding-left: 2rem !important;
}

.pr-7, .px-7 {
  padding-right: 2rem !important;
}

.p-8 {
  padding: 3rem !important;
}

.py-8, .pt-8 {
  padding-top: 3rem !important;
}

.py-8, .pb-8 {
  padding-bottom: 3rem !important;
}

.pl-8, .px-8 {
  padding-left: 3rem !important;
}

.pr-8, .px-8 {
  padding-right: 3rem !important;
}

.gap-auto {
  gap: auto !important;
}

.gapy-auto, .gapt-auto {
  gap-top: auto !important;
}

.gapy-auto, .gapb-auto {
  gap-bottom: auto !important;
}

.gapl-auto, .gapx-auto {
  gap-left: auto !important;
}

.gapr-auto, .gapx-auto {
  gap-right: auto !important;
}

.gap-0 {
  gap: 0 !important;
}

.gapy-0, .gapt-0 {
  gap-top: 0 !important;
}

.gapy-0, .gapb-0 {
  gap-bottom: 0 !important;
}

.gapl-0, .gapx-0 {
  gap-left: 0 !important;
}

.gapr-0, .gapx-0 {
  gap-right: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gapy-1, .gapt-1 {
  gap-top: .25rem !important;
}

.gapy-1, .gapb-1 {
  gap-bottom: .25rem !important;
}

.gapl-1, .gapx-1 {
  gap-left: .25rem !important;
}

.gapr-1, .gapx-1 {
  gap-right: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gapy-2, .gapt-2 {
  gap-top: .5rem !important;
}

.gapy-2, .gapb-2 {
  gap-bottom: .5rem !important;
}

.gapl-2, .gapx-2 {
  gap-left: .5rem !important;
}

.gapr-2, .gapx-2 {
  gap-right: .5rem !important;
}

.gap-3 {
  gap: .75rem !important;
}

.gapy-3, .gapt-3 {
  gap-top: .75rem !important;
}

.gapy-3, .gapb-3 {
  gap-bottom: .75rem !important;
}

.gapl-3, .gapx-3 {
  gap-left: .75rem !important;
}

.gapr-3, .gapx-3 {
  gap-right: .75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gapy-4, .gapt-4 {
  gap-top: 1rem !important;
}

.gapy-4, .gapb-4 {
  gap-bottom: 1rem !important;
}

.gapl-4, .gapx-4 {
  gap-left: 1rem !important;
}

.gapr-4, .gapx-4 {
  gap-right: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gapy-5, .gapt-5 {
  gap-top: 1.25rem !important;
}

.gapy-5, .gapb-5 {
  gap-bottom: 1.25rem !important;
}

.gapl-5, .gapx-5 {
  gap-left: 1.25rem !important;
}

.gapr-5, .gapx-5 {
  gap-right: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gapy-6, .gapt-6 {
  gap-top: 1.5rem !important;
}

.gapy-6, .gapb-6 {
  gap-bottom: 1.5rem !important;
}

.gapl-6, .gapx-6 {
  gap-left: 1.5rem !important;
}

.gapr-6, .gapx-6 {
  gap-right: 1.5rem !important;
}

.gap-7 {
  gap: 2rem !important;
}

.gapy-7, .gapt-7 {
  gap-top: 2rem !important;
}

.gapy-7, .gapb-7 {
  gap-bottom: 2rem !important;
}

.gapl-7, .gapx-7 {
  gap-left: 2rem !important;
}

.gapr-7, .gapx-7 {
  gap-right: 2rem !important;
}

.gap-8 {
  gap: 3rem !important;
}

.gapy-8, .gapt-8 {
  gap-top: 3rem !important;
}

.gapy-8, .gapb-8 {
  gap-bottom: 3rem !important;
}

.gapl-8, .gapx-8 {
  gap-left: 3rem !important;
}

.gapr-8, .gapx-8 {
  gap-right: 3rem !important;
}

.mt-min2 {
  margin-top: -.5rem;
}

.border {
  border: 1px solid #000;
}

.border-top, hr {
  border-top: 1px solid #000;
}

.border-top-none {
  border-top: none;
}

.border-right {
  border-right: 1px solid #000;
}

.border-right-none {
  border-right: none;
}

.border-bottom {
  border-bottom: 1px solid #000;
}

.border-bottom-none {
  border-bottom: none;
}

.border-left {
  border-left: 1px solid #000;
}

.border-left-none {
  border-left: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

.border-none {
  border: none !important;
}

.border-radius-sm {
  border-radius: 5px;
}

.border-radius {
  border-radius: 10px;
}

.border-radius-lg {
  border-radius: 15px;
}

.border-radius-pill {
  border-radius: 9001px;
}

.border-radius-circle {
  border-radius: 50%;
}

.outline-none {
  outline: none;
}

.text-primary, .text-hover-primary:hover {
  color: #008bdc !important;
}

.bg-primary {
  background-color: #008bdc !important;
}

.border-primary {
  border-color: #008bdc !important;
}

.btn-primary {
  color: #fff;
  background-color: #008bdc;
  border: 1px solid #008bdc;
}

.btn-primary:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-primary {
  background-color: #2ab0ff;
}

.text-primary-light, .text-hover-primary-light:hover {
  color: #008bdc80 !important;
}

.bg-primary-light {
  background-color: #008bdc80 !important;
}

.border-primary-light {
  border-color: #008bdc80 !important;
}

.btn-primary-light {
  color: #fff;
  background-color: #008bdc80;
  border: 1px solid #008bdc80;
}

.btn-primary-light:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-primary-light {
  background-color: #2ab0ff80;
}

.text-secondary, .text-hover-secondary:hover {
  color: #044e78cc !important;
}

.bg-secondary {
  background-color: #044e78cc !important;
}

.border-secondary {
  border-color: #044e78cc !important;
}

.btn-secondary {
  color: #fff;
  background-color: #044e78cc;
  border: 1px solid #044e78cc;
}

.btn-secondary:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-secondary {
  background-color: #067ec2cc;
}

.text-success, .text-hover-success:hover {
  color: #34bb1e !important;
}

.bg-success {
  background-color: #34bb1e !important;
}

.border-success {
  border-color: #34bb1e !important;
}

.btn-success {
  color: #fff;
  background-color: #34bb1e;
  border: 1px solid #34bb1e;
}

.btn-success:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-success {
  background-color: #5ae144;
}

.text-success-light, .text-hover-success-light:hover {
  color: #34bb1e80 !important;
}

.bg-success-light {
  background-color: #34bb1e80 !important;
}

.border-success-light {
  border-color: #34bb1e80 !important;
}

.btn-success-light {
  color: #fff;
  background-color: #34bb1e80;
  border: 1px solid #34bb1e80;
}

.btn-success-light:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-success-light {
  background-color: #5ae14480;
}

.text-warning, .text-hover-warning:hover {
  color: #feb822 !important;
}

.bg-warning {
  background-color: #feb822 !important;
}

.border-warning {
  border-color: #feb822 !important;
}

.btn-warning {
  color: #000;
  background-color: #feb822;
  border: 1px solid #feb822;
}

.btn-warning:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-warning {
  background-color: #fed06e;
}

.text-warning-light, .text-hover-warning-light:hover {
  color: #feb82280 !important;
}

.bg-warning-light {
  background-color: #feb82280 !important;
}

.border-warning-light {
  border-color: #feb82280 !important;
}

.btn-warning-light {
  color: #000;
  background-color: #feb82280;
  border: 1px solid #feb82280;
}

.btn-warning-light:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-warning-light {
  background-color: #fed06e80;
}

.text-danger, .text-hover-danger:hover {
  color: #ee4545 !important;
}

.bg-danger {
  background-color: #ee4545 !important;
}

.border-danger {
  border-color: #ee4545 !important;
}

.btn-danger {
  color: #fff;
  background-color: #ee4545;
  border: 1px solid #ee4545;
}

.btn-danger:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-danger {
  background-color: #f48b8b;
}

.text-danger-light, .text-hover-danger-light:hover {
  color: #ee454580 !important;
}

.bg-danger-light {
  background-color: #ee454580 !important;
}

.border-danger-light {
  border-color: #ee454580 !important;
}

.btn-danger-light {
  color: #fff;
  background-color: #ee454580;
  border: 1px solid #ee454580;
}

.btn-danger-light:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-danger-light {
  background-color: #f48b8b80;
}

.text-gray-darker, .text-hover-gray-darker:hover {
  color: #b7b7b7 !important;
}

.bg-gray-darker {
  background-color: #b7b7b7 !important;
}

.border-gray-darker {
  border-color: #b7b7b7 !important;
}

.btn-gray-darker {
  color: #000;
  background-color: #b7b7b7;
  border: 1px solid #b7b7b7;
}

.btn-gray-darker:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-gray-darker {
  background-color: #ddd;
}

.text-gray-dark, .text-hover-gray-dark:hover {
  color: #c7c7c7 !important;
}

.bg-gray-dark {
  background-color: #c7c7c7 !important;
}

.border-gray-dark {
  border-color: #c7c7c7 !important;
}

.btn-gray-dark {
  color: #000;
  background-color: #c7c7c7;
  border: 1px solid #c7c7c7;
}

.btn-gray-dark:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-gray-dark {
  background-color: #ededed;
}

.text-gray, .text-hover-gray:hover {
  color: #d9d9d9 !important;
}

.bg-gray {
  background-color: #d9d9d9 !important;
}

.border-gray {
  border-color: #d9d9d9 !important;
}

.btn-gray {
  color: #000;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.btn-gray:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-gray {
  background-color: #fff;
}

.text-gray-light, .text-hover-gray-light:hover {
  color: #ebebeb !important;
}

.bg-gray-light {
  background-color: #ebebeb !important;
}

.border-gray-light {
  border-color: #ebebeb !important;
}

.btn-gray-light {
  color: #000;
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
}

.btn-gray-light:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-gray-light {
  background-color: #fff;
}

.text-gray-lighter, .text-hover-gray-lighter:hover {
  color: #f5f5f5 !important;
}

.bg-gray-lighter {
  background-color: #f5f5f5 !important;
}

.border-gray-lighter {
  border-color: #f5f5f5 !important;
}

.btn-gray-lighter {
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

.btn-gray-lighter:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-gray-lighter {
  background-color: #fff;
}

.text-white, .text-hover-white:hover {
  color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
}

.btn-white:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-white {
  background-color: #fff;
}

.text-white-opaque, .text-hover-white-opaque:hover {
  color: #fff6 !important;
}

.bg-white-opaque {
  background-color: #fff6 !important;
}

.border-white-opaque, hr {
  border-color: #fff6 !important;
}

.btn-white-opaque {
  color: #000;
  background-color: #fff6;
  border: 1px solid #fff6;
}

.btn-white-opaque:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-white-opaque {
  background-color: #fff6;
}

.text-black, .text-hover-black:hover {
  color: #000 !important;
}

.bg-black {
  background-color: #000 !important;
}

.border-black {
  border-color: #000 !important;
}

.btn-black {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}

.btn-black:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-black {
  background-color: #262626;
}

.text-black-50, .text-hover-black-50:hover {
  color: #00000080 !important;
}

.bg-black-50 {
  background-color: #00000080 !important;
}

.border-black-50 {
  border-color: #00000080 !important;
}

.btn-black-50 {
  color: #fff;
  background-color: #00000080;
  border: 1px solid #00000080;
}

.btn-black-50:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-black-50 {
  background-color: #26262680;
}

.text-black-40, .text-hover-black-40:hover {
  color: #0006 !important;
}

.bg-black-40 {
  background-color: #0006 !important;
}

.border-black-40 {
  border-color: #0006 !important;
}

.btn-black-40 {
  color: #fff;
  background-color: #0006;
  border: 1px solid #0006;
}

.btn-black-40:hover:not(.btn-disabled), .btn.btn-labeled:hover .bg-black-40 {
  background-color: #26262666;
}

.bg-none {
  background: none;
}

.btn-white {
  border: 1px solid #ebebeb;
}

.btn-white:hover:not(.btn-disabled) {
  background-color: #008bdc;
  border-color: #008bdc;
  color: #fff !important;
}

.btn-white:hover:not(.btn-disabled) .fas {
  color: #fff !important;
}

.btn-none {
  color: inherit;
  background-color: #0000;
  border: none;
  padding: 0;
}

.btn-disabled {
  opacity: .2;
  cursor: not-allowed;
  border: #0000;
}

.text-left {
  text-align: left;
}

.text-center, .d-flex-list li .fas {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-decoration-none {
  text-decoration: none;
}

.text-underline {
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.fw-normal {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.font-size-body {
  font-size: 16px;
}

i, em, .fs-italic {
  font-style: italic;
}

.h-100 {
  height: 100%;
}

.maxh-100 {
  max-height: 100%;
}

.minh-100 {
  min-height: 100%;
}

.maxh-100dvh {
  max-height: 100dvh;
}

.minh-100dvh {
  min-height: 100dvh;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-50 {
  width: 50%;
}

.w-fit-content {
  width: fit-content;
}

.minw-100 {
  min-width: 100%;
}

.maxw-300px {
  max-width: 300px;
}

.maxw-500px {
  max-width: 500px;
}

.word-break-break-word {
  word-break: break-word;
}

.alert {
  pointer-events: auto;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  padding: 1.25rem 1.5rem;
  position: relative;
}

.alert-success {
  background-color: #ebf8e9;
  border-color: #34bb1e;
}

.alert-warning {
  background-color: #fff8e9;
  border-color: #feb822;
}

.alert-danger {
  background-color: #fdecec;
  border-color: #ee4545;
}

.text-truncate-3-lines {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.small {
  font-size: 14px;
}

.smaller {
  font-size: 12px;
}

.ellipse {
  background-color: #d9d9d9;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.fixed-bottom-right {
  z-index: 1000;
  background: #fff;
  border-top: 1px solid #ebebeb;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 57px);
  padding: 1rem 3rem;
  transition: width .21s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

.icon-spacing {
  margin-right: .5rem;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
  max-width: calc(100vw - 57px - 6rem);
  margin-bottom: 1rem;
  display: block;
  overflow-x: auto;
}

body:has(.menu-checkbox:checked) .table-responsive {
  max-width: calc(100vw - 281px - 6rem);
}

.has-sidebar .table-responsive {
  max-width: calc(100vw - 357px - 6rem);
}

body:has(.menu-checkbox:checked) .has-sidebar .table-responsive {
  max-width: calc(100vw - 581px - 6rem);
}
/*# sourceMappingURL=index.css.map */
