
// custom css for autocomplete search (strings.html.twig edit)
// -- NOTE: replace with proper styling
.autocomplete-container {
    width: 300px;
    position: relative;
    font-family: Arial, sans-serif;
}

.suggestions {
    border: $border-width solid #ccc;
    background: #fff;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.suggestion-item:hover {
    background: #f0f0f0;
}

.no-suggestions {
    padding: 8px;
    color: #999;
}

.selected-items {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.selected-item {
    background: #e0e0e0;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.remove-item-btn {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: #333;
}

.autocomplete-suggestions {
    display: none; /* Initially hidden */
    border: $border-width solid #ccc;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
    width: 100%;
}

.suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.no-suggestions {
    color: #999;
    text-align: center;
    padding: 8px;
}
