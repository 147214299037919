@use 'sass:color';
@use 'sass:map';
@use 'sass:math';

:root {
    interpolate-size: allow-keywords;
}

* {
    margin: 0;
    border: none;
    padding: 0;
    box-sizing: border-box;
    outline: none;

    &:focus-visible {
        outline: none;
    }

    &::after,
    &::before {
        box-sizing: border-box;
    }
}

html {
    height: 100dvh;
    width: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    background-color: $white;
    font-family: museo-sans, sans-serif;
    font-weight: $font-weight;
    font-size: $body-font-size;
    line-height: 1.5;
    color: $black;
    height: 100%;
}

*::selection {
    text-shadow: 0.05em 0.05em 0.01em rgba(0, 0, 0, 0.4);
    background-color: rgba($primary, 0.6);
    color: white;
}

section {
    padding: spacer(8);
}


h1,
h2,
h3,
h4 {
    font-weight: $font-weight-bold;

    small {
        font-weight: $font-weight;
    }
}

h1 {
    font-size: $h1-font-size;
    margin-bottom: spacer(6);
}

h2 {
    font-size: $h2-font-size;
    margin-bottom: spacer(6);
}

h3 {
    margin-bottom: spacer(5);
    font-size: $h3-font-size;
}

h4 {
    font-size: $body-font-size;
    font-weight: $font-weight-bold;

    .fas {
        font-size: $small-font-size
    }
}

.sidemenu {
    h2 {
        font-size: 15px;
        margin-bottom: 0;
    }

    h3 {
        font-size: 14px;
        margin-bottom: 0;

    }

    .func-dropdown-checkbox-parent-0 {
        height: 58px;
    }

    .func-dropdown-checkbox-parent-1 {
        height: 48px;
    }
}

small {
    font-size: $small-font-size;
}

hr {
    @extend .border-top, .border-white-opaque;
    height: 0;
}

a {
    color: $primary;

    li &,
    &.sortable,
    &.asc,
    &.desc {
        text-decoration: none;
        color: $black;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
        }
    }
}

ul {
    padding-left: spacer(6);

    li {
        line-height: 1.5em;
        position: relative;
    }

    &.bullet-list {
        list-style-type: disc;
        list-style-position: inside;
        margin-left: spacer(4);
        padding-left: 0;
    }
}

.side-menu:has(.menu-checkbox:checked) {

    li,
    hr+div {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            max-height: 64px;
            width: 100%;
            transform: scaleX(0);
            transform-origin: left;
            background-color: color.adjust($primary, $lightness: -5%);
            transition: transform $animationSpeed;
            z-index: 0;
        }

        span {
            position: relative;
            z-index: 1;
        }
    }

    label {
        position: relative;
    }

    hr+div:hover,
    li:hover {
        &::before {
            transform: scaleX(1);
        }
    }

    hr+div:hover:has(li:hover) {
        &::before {
            transform: scaleX(0);
        }
    }
}

.btn,
button {
    cursor: pointer;
    border: none;
    border-radius: $border-radius-pill;
    padding: spacer(3) spacer(4);
    font-size: $small-font-size;
    font-weight: $font-weight-bold;
    line-height: 16px;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
}

.btn-lg {
    padding: spacer(4) spacer(6);
    font-size: $body-font-size;
}

.btn-sm {
    border-radius: $border-radius-lg;
    padding: spacer(1) spacer(3);
}

.circle-btn {
    width: 24px;
    height: 24px;
    padding: 0 !important;
}

select,
textarea,
input {
    width: 100%;
    border: $border-width solid $gray;
    border-radius: $border-radius;
    font-size: $body-font-size;
    line-height: $input-line-height;
    padding: spacer(4) spacer(5);
    font-family: museo-sans, sans-serif;

    &[type=submit] {
        width: auto;
    }

    &[type=radio] {
        width: auto;
    }

    &[type=date] {
        padding: spacer(3);
        border-color: $black;
    }

    &[type=text] {
        &:disabled {
            opacity: 0.2;
        }
    }

    .login-menu & {
        margin: spacer(3) 0 spacer(6);
    }

    &.custom-radio,
    &.custom-checkbox {
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
    }

    &:focus {
        border-color: $primary;
    }

    &::placeholder {
        font-weight: $font-weight;
        font-size: $border-radius-lg;
        font-style: italic;
        font-family: museo-sans, sans-serif;
    }

    .floating-label-wrapper & {
        +.floating-label {
            position: absolute;
            top: 50%;
            left: spacer(5);
            transform: translateY(-50%) skew(-15deg);
            pointer-events: none;
            color: $black-50;
            background: linear-gradient(to bottom, transparent 50%, $white 50%);
            transition-duration: 300ms;
        }

        &:focus~.floating-label {
            color: $primary;
        }

        &:focus~.floating-label,
        &:not(:placeholder-shown)~.floating-label {
            top: 0;
            left: calc(spacer(5) - spacer(2) + $border-width);
            transform: translateY(-50%);
            padding: 0 spacer(1);
            font-size: $smaller-font-size;
        }

        &[disabled=disabled] {
            background: $gray-lighter;

            +.floating-label {
                background: linear-gradient(to bottom, transparent 50%, $gray-lighter 50%);
            }
        }
    }
}

.custom-slide-btn {
    width: 106px;
    height: 32px;
    display: inline-block;
    background: $gray-lighter;
    position: relative;
    border-radius: $border-radius-pill;
    font-weight: $font-weight-medium;

    input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    .circle {
        border-radius: $border-radius-pill;
        width: 25px;
        height: 25px;
        position: absolute;
        background: $gray;
        transition: all $animationSpeed;
        left: 6px;
        top: 3px;
    }

    .text {
        position: absolute;
        text-align: center;
        color: $black;
        top: 3px;
        width: 23px;
        height: 24px;
        right: 30px;

        &:before {
            content: attr(data-text-false);
        }
    }

    &:has(input:checked) {
        background: $primary;

        .text {
            right: 50px;

            &:before {
                content: attr(data-text-true);
                color: $white;
                font-weight: $font-weight-bold;
            }
        }

        .circle {
            left: 77px;
            background: $white;
        }

    }
}

.floating-label-wrapper {
    position: relative;
}

.create-survey-form>.language-input {
    margin-bottom: spacer(6);
}

.draggable-input-wrapper {
    .fas.fa-arrows-alt {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: spacer(5);
        cursor: grab;
    }

    .floating-label-wrapper {
        margin: 0;
    }
}

textarea {
    resize: vertical;
}

label {
    cursor: pointer;

    &:has(.custom-checkbox),
    &:has(.custom-radio) {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: calc(spacer(4) + $custom-radio-height - ($border-width * 2));

        &::before {
            background-color: $white;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: $custom-radio-height;
            width: $custom-radio-height;
            border: $border-width solid $gray-darker;
            justify-content: center;
            align-items: center;
            font-size: $small-font-size;
            text-align: center;
        }

        &:has(:disabled) {
            opacity: 0.4;
            filter: grayscale(1);

            &:hover {
                .can-be-enabled & {
                    opacity: .7;
                    filter: grayscale(0.3);

                }
            }
        }
    }

    &:has(.custom-checkbox) {
        &::before {
            border-radius: $border-radius-sm;
        }

    }

    &:has(.custom-radio) {
        height: $custom-radio-height;

        &:has(.custom-radio.radio-only) {
            padding-left: $custom-radio-height;
        }

        &::after,
        &::before {
            border-radius: $border-radius-pill;
        }
    }

    &:has(.custom-checkbox:checked),
    &:has(.custom-radio:checked) {
        &::after {
            position: absolute;
            top: math.div($custom-radio-height, 2);
            left: math.div($custom-radio-height, 2);
            transform: translate(-50%, -50%);

        }

    }

    &:has(.custom-checkbox:checked)::after {}

    &:has(.custom-checkbox:checked) {
        &::before {
            background-color: $primary;
            border-color: $primary;
        }

        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: $font-weight-bold;
            font-size: $small-font-size;
            content: "\f00c";
            position: absolute;
            color: $white;
        }
    }

    &:has(.custom-radio:checked) {
        &::before {
            background-color: $white;
            border-color: $gray-darkest;
        }

        &::after {
            content: "";
            position: absolute;
            text-align: center;
            color: $primary;
            background-color: $primary;
            width: math.div($custom-radio-height, 2);
            height: math.div($custom-radio-height, 2);
        }
    }
}

.switch-label {
    .switch-checkbox {
        cursor: pointer;
        width: 0;
        height: 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -5px;
            right: 0;
            height: 20px;
            width: 37px;
            background-color: $gray;
            border-radius: 20px;
            transition: .4s;
        }

        &::after {
            content: "";
            position: absolute;
            top: -3px;
            right: 18px;
            height: 16px;
            width: 16px;
            background-color: $white;
            border-radius: $border-radius-pill;
            transition: .4s;
        }

        &:checked {
            &::before {
                background-color: $primary;
            }

            &::after {
                right: 2px;
            }
        }
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

select {
    border-radius: $border-radius;
    border: $border-width solid $gray;
    padding: spacer(4) spacer(5);
    background-color: $white;
    font-size: $body-font-size;

    &.select-sm {
        border-color: $gray-darker;
        padding: spacer(3) 16px;
        font-size: $small-font-size;
    }
}

.overlay-primary {
    background-size: cover;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 80%;
        background-color: $primary;
    }
}

.two-fa-input {
    width: 370px;
    letter-spacing: 45px;
    font-family: monospace;
}

////////////////
//INTERN ALERT//
////////////////

// two table classes
// .table-striped (tr nth of type 2 is background $gray (and table header)
// .table-
table {
    width: 100%;
    background-color: $white;
    border: $border-width solid $gray-light;
    border-radius: $border-radius-lg;
    border-spacing: 0;
    border-collapse: separate;

    td:has(.custom-radio) {
        align-items: center;
    }

    &.table-striped {

        tr:nth-child(even) td,
        th {
            background-color: $gray-lighter;
            border-color: $gray;
        }
    }

    &.table-conditional-lines {
        tbody tr {

            td:nth-child(2),
            td:nth-child(3),
            td:nth-child(4) {
                border-left: 1px solid $gray;
            }

            td:nth-child(4) {
                border-right: 1px solid $gray;
            }
        }
    }

    &.table-primary-line {
        tr {

            td:nth-child(4),
            th:nth-child(4) {
                border-right: 2px solid $primary !important;
            }
        }

        tbody tr.table-conditional-footer {
            color: $white;
            background-color: $gray-lighter;

            td {
                background-color: $primary;
                border: $primary;
                text-align: center;
            }

            td:first-of-type {
                text-align: left;
                border-bottom-left-radius: $border-radius-lg;
            }

            td:last-of-type {
                border-bottom-right-radius: $border-radius-lg;
            }
        }
    }

    tr.conditional-history-row {
        td {
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
        }
    }

    .hidden-row {

        th,
        td {
            border: none;
            padding: 1rem 0 0;

            &[data-label-text] {
                border-left: 1px solid $gray;

                &+td,
                th {
                    border-left: 1px solid $gray;

                    &.border-primary {
                        border-width: 2px;
                    }
                }
            }
        }
    }

    &.table-angled-headers {
        margin-top: 9.5rem;

        th,
        td {
            position: relative;

            &[data-label-text] {
                &::before {
                    content: attr(data-label-text);
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: rotate(-50deg);
                    transform-origin: -13px 17px;
                    width: 12rem;
                    padding-left: 0rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: -1px;
                    transform: skew(-40deg);
                    transform-origin: bottom right;
                    width: calc(100% + 2px);
                    height: 8rem;
                    border-right: 2px solid $gray;
                }

                &:nth-of-type(2)::after {
                    border-left: 2px solid $gray;
                }

                &.data-label-primary {
                    &::after {
                        width: calc(100% + 4px);
                        border-right: 3px solid $primary;
                    }
                }
            }
        }

        th {
            text-align: center;
        }
    }
}

td {
    border: none;
    padding: spacer(4) spacer(6);
    vertical-align: middle;

    a {
        text-decoration: none;
        color: $white;
    }
}

th {
    font-size: 17px;
    text-align: left;
    background-color: $gray-lighter;
    padding: spacer(5) spacer(6);
    position: relative;
    vertical-align: middle;

    .fas {
        pointer-events: none;
    }

    &+th {
        border-left: $border-width solid $gray;
    }

    &:first-child {
        border-top-left-radius: $border-radius;
    }

    &:last-child {
        border-top-right-radius: $border-radius;
    }
}

// -- NOTE: Unused, can be removed??
.tooltip-container {
    position: relative;
    display: inline-block;

    .tooltip-text {
        visibility: hidden;
        width: 28rem;
        background-color: $primary;
        color: white;
        text-align: center;
        padding: spacer(3);
        border-radius: $border-radius-sm;
        position: absolute;
        bottom: 150%;
        font-weight: $font-weight;
        font-family: museo-sans, sans-serif;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity $animationSpeed;


        $arrowSize: 10px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: $arrowSize solid transparent;
            border-right: $arrowSize solid transparent;
            border-top: $arrowSize solid $primary;
        }
    }

    &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}

// checkbox magic for menu
.menu-checkbox {
    display: none;

}

.func-drowpdown-checkbox {
    display: none;
}

$menuClosedWidth: 57px;
$menuOpenWidth: 281px;
$sidebarWidth: 300px;

.menu-checkbox-parent {
    min-width: $menuClosedWidth;
    max-width: $menuClosedWidth;
    transition: min-width $animationSpeed ease-in-out;
    overflow-x: hidden;
    position: sticky;
    top: 0;
    max-height: 100dvh;

    .show-if-checked {
        display: none;
    }

    .fas+span {
        margin-left: spacer(8) !important;
        transition: margin-left $animationSpeed ease-in-out;
    }

    li {
        padding-left: $spacer * 9 !important;
        transition: padding-left $animationSpeed ease-in-out;
    }

    &:has(.menu-checkbox:checked) {
        min-width: $menuOpenWidth;

        .show-if-checked {
            display: block;
        }

        .hide-if-checked {
            display: none;
        }

        .fas+span {
            margin-left: spacer(4) !important;
        }

        li {
            padding-left: spacer(7) !important;
        }

    }
}

.func-visibility-checkbox-parent {
    padding: 0;
    align-content: center;

    .menu-checkbox,
    .func-tooltip-checkbox,
    .func-title-input-checkbox {
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
    }

    &:after,
    &:before {
        display: none;
    }
}

@each $depth in (0, 1, 2) {
    .func-dropdown-checkbox-parent-#{$depth} {
        height: 64px;
        transition: height $animationSpeed ease-in-out;
        overflow-y: hidden;
        overflow-x: hidden;

        .draggable-container & {
            height: 21px;
        }

        &.chapter-wrapper,
        &.section-wrapper {
            height: 88px;
        }

        &.question-dropdown-parent {
            height: 91px;
        }

        .dropdown-arrow-transform-#{$depth} {
            transform: rotate(0deg);
            transition: transform $animationSpeed ease-in-out;
        }

        &:has(.func-dropdown-checkbox-#{$depth}:checked) {
            height: auto;

            .dropdown-arrow-transform-#{$depth} {
                transform: rotate(180deg);
            }
        }


        .func-visibility-checkbox-parent {
            .func-dropdown-checkbox-#{$depth} {
                position: absolute;
                height: 0;
                width: 0;
                top: 0;
                left: 0;
                margin: 0;
                opacity: 0;
            }
        }
    }
}

// Checkbox magic for tooltip
.func-tooltip-checkbox-parent {
    position: relative;

    .tooltip {
        pointer-events: none;
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        min-width: 100%;
        box-shadow: 0 spacer(2) spacer(4) $gray;
        border: $border-width solid $gray;
        border-radius: $border-radius;
        background-color: white;
        padding: spacer(3);
        opacity: 0;
        transition-duration: $animationSpeed;
        z-index: 9001;
    }

    &:has(.func-tooltip-checkbox:checked) {
        .tooltip {
            pointer-events: all;
            opacity: 1;
        }
    }
}

.checkbox-rows-parent {
    display: flex;
    justify-content: space-between;

}

.js-check-radio-table .menu-items {
    a {
        text-decoration: none;
        color: $white;
    }
}

// Sortable toggle
.func-sortable-toggle {
    &+.func-togglable-sortable-container {
        .fa-arrows-alt {
            display: none;
        }
    }

    &:has(.switch-checkbox:checked) {
        &+.func-togglable-sortable-container {

            .func-toggle-copy-button,
            .func-toggle-delete-button {
                display: none;
            }

            .func-toggle-handle {
                cursor: grab;
                display: block;
            }
        }
    }
}

$languageCount: 10; // -- NOTE: this should maybe come from .env?

.language-input {
    position: relative;
    min-width: 250px;

    input[type=text] {
        display: none;
    }

    .language-select {
        cursor: pointer;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        right: 10px;
        background: linear-gradient(#0000 39%, #fff 39%);
        padding: 0 8px;
        z-index: 1;

        input[type="radio"] {
            display: none;
        }

        label {
            cursor: pointer;

            img {
                opacity: .6;
                filter: grayscale(.5);
                border: 1px solid $gray;
            }

            &:hover,
            &:has(input[type="radio"]:checked) {
                img {
                    opacity: 1;
                    filter: grayscale(0);
                }
            }
        }
    }

    @for $i from 1 through $languageCount {

        // input nth of type should be set to visible for the radio nth of type
        &:has(label:nth-of-type(#{$i}) input[type="radio"]:checked) {
            input[type=text]:nth-of-type(#{$i}) {
                display: block;
            }
        }
    }
}

.subquestion-sortable-container {
    margin-left: spacer(7);
}

.func-sortable-column-wrapper {
    &:has(a.sortable) {
        .fa-sortable-arrows {
            transform: rotate(90deg);
            transition: transform $animationSpeed ease-in-out;

            &::before {
                content: "\f362";
            }
        }
    }

    &:has(a.asc) {
        .fa-sortable-arrows {
            transition: transform $animationSpeed ease-in-out;

            &::before {
                transition: transform $animationSpeed ease-in-out;
                content: "\f30c";
            }
        }
    }

    &:has(a.desc) {
        .fa-sortable-arrows {
            transition: transform $animationSpeed ease-in-out;

            &::before {
                transition: transform $animationSpeed ease-in-out;
                content: "\f309";
            }
        }
    }
}

a>svg.loading {

    // first path in the svg can have a color cicle (gradient) between primary and secondary
    path:first-child {
        fill: $primary;
        animation: loading $animationSpeed * 5 infinite;
    }
}

@keyframes loading {
    0% {
        fill: $primary;
    }

    50% {
        fill: #5cc3ff;
    }

    100% {
        fill: $primary;
    }
}

.pagination {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }

        &.disabled {
            color: #949494; // Lighter primary for disabled state
            background-color: transparent;
            border: none;
            cursor: not-allowed;
        }

        .page-link {
            display: inline-block;
            padding: 0.7rem 1rem;
            background-color: $white;
            border-radius: $border-radius;
            border: $border-width solid $gray-light;
            border-radius: 0.5rem;
            text-decoration: none;

            &:hover {
                color: #fff;
                background-color: $primary; // Background color on hover
                border-color: $primary;
            }
        }

        &.active .page-link {
            color: #fff;
            background-color: $primary; // Active background color
            border-color: $primary;
            font-weight: $font-weight-bold;
        }

        &.disabled .page-link {
            color: $black; // Lighter primary for disabled state
            background-color: transparent;
            border: none;
            cursor: not-allowed;
            font-weight: $font-weight-bold;
        }
    }
}

form:has(.action-bar),
section:has(.action-bar) {
    padding-bottom: 8rem;
}

.action-bar {
    border-top: 1px solid $gray-light;
    background: white;
    padding: spacer(4) 3rem;
    position: fixed;
    bottom: 0;
    right: 0;
    transition: width $animationSpeed ease-in-out;
    width: calc(100% - $menuClosedWidth);
    z-index: 1000;

    body:has(.menu-checkbox:checked) & {
        width: calc(100% - $menuOpenWidth);
    }

    .has-sidebar & {
        width: calc(100% - $menuClosedWidth - $sidebarWidth);
    }

    body:has(.menu-checkbox:checked) .has-sidebar & {
        width: calc(100% - $menuOpenWidth - $sidebarWidth);
    }
}

.func-title-input-checkbox-parent {
    height: calc($body-font-size * $input-line-height + spacer(4) * 2 + $border-width * 2);

    .language-input {
        display: none;
    }

    &:has(.func-title-input-checkbox:checked) {

        .func-title-input-title {
            display: none;
        }

        @for $i from 1 through $languageCount {

            // input nth of type should be set to visible for the radio nth of type
            .language-input:has(label:nth-of-type(#{$i}) input[type="radio"]:checked) {
                display: block;
            }
        }
    }
}

.info-circle {
    width: 26px;
    height: 26px;
    line-height: 25px;
    text-align: center;
    display: inline-block;
    border: 1px solid $primary;
    border-radius: $border-radius-pill;
    font-size: $small-font-size;
    background-color: $white;

    &[data-value="0"] {
        border-color: $gray-light;
    }

    &[data-value="1"] {
        border-color: $primary-light;
    }

    &[data-value="2"] {
        border-color: $primary;
    }
}

///////////////////
/// Apex charts ///
///////////////////
.chart-styling {
    .apexcharts-menu-icon {
        display: none !important;
    }

    .apexcharts-toolbar {

        .apexcharts-menu-icon,
        .apexcharts-pan-icon,
        .apexcharts-reset-icon,
        .apexcharts-selection-icon,
        .apexcharts-toolbar-custom-icon,
        .apexcharts-zoom-icon,
        .apexcharts-zoomin-icon,
        .apexcharts-zoomout-icon {
            width: 44px;
            height: 40px;
            transform: scale(0.6);
        }

        .apexcharts-zoomin-icon,
        .apexcharts-zoomout-icon {
            &:hover {
                background-color: transparent;
                border-radius: $border-radius-pill;

                path+path {
                    fill: $primary;
                }

                cursor: pointer;
            }
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }
}

.fixed-bottom-right {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: white;
    padding: spacer(4) 3rem;
    border-top: 1px solid $gray-light;
    transition: width $animationSpeed ease-in-out;
    width: calc(100% - $menuClosedWidth);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    nav {
        margin: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    max-width: calc(100vw - $menuClosedWidth - (spacer(8) * 2));
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    // Prevent double border on horizontal scroll due to use of `display: block;`
    >.table-bordered {
        border: 0;
    }

    // Add bottom margin for better spacing when scrolling
    margin-bottom: spacer(4);

    // Ensure smooth scrolling on iOS
    -webkit-overflow-scrolling: touch;

    // Adjust max-width when menu is open
    body:has(.menu-checkbox:checked) & {
        max-width: calc(100vw - $menuOpenWidth - (spacer(8) * 2));
    }

    // Adjust max-width when sidebar is present
    .has-sidebar & {
        max-width: calc(100vw - $menuClosedWidth - $sidebarWidth - (spacer(8) * 2));
    }

    // Adjust max-width when both menu is open and sidebar is present
    body:has(.menu-checkbox:checked) .has-sidebar & {
        max-width: calc(100vw - $menuOpenWidth - $sidebarWidth - (spacer(8) * 2));
    }
}

// Search input with icons
.search-input-container {
    position: relative;

    .search-input {
        padding-right: 4.5rem; // Space for both icons
    }

    .search-action-buttons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.5rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .search-icon-btn,
        .clear-icon-btn {
            background: transparent;
            border: 0;
            color: $black;
            padding: 0.375rem;
            line-height: 1;
            transition: color 0.2s ease;

            &:hover {
                color: $primary;
            }
        }
    }
}

// Timeline styles
.timeline-container {
    padding: spacer(4) 0;
}

.timeline-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.timeline-line {
    height: 2px;
    background: $gray;
    top: 15px;
    z-index: 0;
    position: absolute;
    width: 100%;
}

.timeline-item {
    flex: 1;
    position: relative;
    z-index: 1;
    text-align: center;

    &.current-month {
        background: rgba($primary, 0.1);
        padding: spacer(2);
        border-radius: $border-radius;
        margin-top: -10px;
        margin-bottom: -10px;
    }
}

.timeline-dot {
    width: 12px;
    height: 12px;
    background: $gray;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
}

.timeline-month {
    font-size: $small-font-size;
    color: $gray-dark;
    margin-top: spacer(2);
}

.visit-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
    background: $primary;
    color: $white;
    text-decoration: none;
    position: relative;

    &:hover {
        background: color.adjust($primary, $lightness: -10%);
    }
}

.add-visit-wrapper {
    text-align: right;
    margin-top: spacer(3);
}

.icon-spacing {
    margin-right: spacer(2);
}