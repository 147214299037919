@use 'sass:color';

// Display
@each $display in (flex, block, inline, inline-block, none) {
    .d-#{$display} {
        display: $display;
    }
}

@each $overflow in (auto, hidden) {
    .overflow-#{$overflow} {
        overflow: $overflow;
    }
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

// Flex
@each $flex-position in (start, end, center, space-between, around) {
    .justify-content-#{$flex-position} {
        justify-content: $flex-position;
    }

    .align-items-#{$flex-position} {
        align-items: $flex-position;
    }
}

@each $flex-direction in (row, column) {
    .flex-#{$flex-direction} {
        flex-direction: $flex-direction;
    }
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.flex-basis-25 {
    flex-basis: 25%;
}

.flex-basis-75 {
    flex-basis: 75%;
}

.d-flex-list {
    li {
        @extend .d-flex, .align-items-center;

        .fas {
            width: spacer(5);
            @extend .text-center;

            &+* {
                margin-left: spacer(2);
                @extend .flex-grow-1;
            }
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

// Positions
@each $position in (relative, absolute, fixed, sticky) {
    .position-#{$position} {
        position: $position;
    }
}

@each $position in (top, right, bottom, left) {
    .#{$position}-0 {
        #{$position}: 0;
    }
}

@each $alignment in (top, middle, bottom, baseline) {
    .vertical-align-#{$alignment} {
        vertical-align: $alignment;
    }
}

.left-4 {
    left: spacer(4);
}

.align-content-center {
    align-content: center;
}

.position-center-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-vertical-center {
    top: 50%;
    transform: translateY(-50%);
}

.z-index-1000 {
    z-index: 1000;
}

.float-right {
    float: right;
}

.clear-both {
    clear: both;
}


// Spacers
@each $prop, $abbrev in (margin: m, padding: p, gap: gap) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }

        .#{$abbrev}y-#{$size},
        .#{$abbrev}t-#{$size} {
            #{$prop}-top: $length !important;
        }

        .#{$abbrev}y-#{$size},
        .#{$abbrev}b-#{$size} {
            #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }

        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
    }
}

.mt-min2 {
    margin-top: spacer(2) * -1;
}

// Borders
.border {
    border: $border-width solid $black;
}

@each $border in (top, right, bottom, left) {
    .border-#{$border} {
        border-#{$border}: $border-width solid $black;
    }

    .border-#{$border}-none {
        border-#{$border}: none;
    }
}

.rotate-90 {
    transform: rotate(90deg);
}

.border-none {
    border: none !important;
}

.border-radius-sm {
    border-radius: $border-radius-sm;
}

.border-radius {
    border-radius: $border-radius;
}

.border-radius-lg {
    border-radius: $border-radius-lg;
}

.border-radius-pill {
    border-radius: $border-radius-pill;
}

.border-radius-circle {
    border-radius: 50%;
}

.outline-none {
    outline: none;
}

// Colors
@each $key, $value in $theme-colors {
    $keyStr: "" + $key;

    .text-#{$keyStr} {
        color: $value !important;
    }

    .text-hover-#{$keyStr}:hover {
        color: $value !important;
    }

    .bg-#{$keyStr} {
        background-color: $value !important;
    }

    .border-#{$keyStr} {
        border-color: $value !important;
    }

    .btn-#{$keyStr} {
        background-color: $value;
        color: textColorBasedOnBackground($value);
        border: $border-width solid $value;

        &:hover:not(.btn-disabled) {
            background-color: color.adjust($value, $lightness: 15%);
        }
    }

    .btn.btn-labeled:hover {
        .bg-#{$keyStr} {
            background-color: color.adjust($value, $lightness: 15%);
        }
    }
}

.bg-none {
    background: none;
}

.btn-white {
    border: $border-width solid $gray-light;

    &:hover:not(.btn-disabled) {
        background-color: $primary;
        color: $white !important;
        border-color: $primary;

        .fas {
            color: $white !important;
        }
    }
}

.btn-none {
    background-color: transparent;
    color: inherit;
    border: none;
    padding: 0;
}

.btn-disabled {
    border: transparent;
    opacity: 20%;
    cursor: not-allowed;
}

// Text
@each $alignment in (left, center, right) {
    .text-#{$alignment} {
        text-align: $alignment;
    }
}

.text-decoration-none {
    text-decoration: none;
}

.text-underline {
    text-decoration: underline;
}

.nowrap {
    white-space: nowrap;
}

.fw-normal {
    font-weight: $font-weight;
}

.fw-medium {
    font-weight: $font-weight-medium;
}

.font-size-body {
    font-size: $body-font-size;
}

i,
em,
.fs-italic {
    font-style: italic;
}


// Sizes
.h-100 {
    height: 100%;
}

@each $key, $value in ('100': 100%, '100dvh': 100dvh) {
    .maxh-#{$key} {
        max-height: $value;
    }

    .minh-#{$key} {
        min-height: $value;
    }
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.w-50 {
    width: 50%;
}

.w-fit-content {
    width: fit-content;
}

.minw-100 {
    min-width: 100%;
}

.maxw-300px {
    max-width: 300px;
}

.maxw-500px {
    max-width: 500px;
}

.word-break-break-word {
    word-break: break-word;
}

// Alert boxes
.alert {
    border-width: 2px;
    border-style: solid;
    border-radius: $border-radius-sm;
    padding: spacer(5) spacer(6);
    position: relative;
    pointer-events: auto;
}

.alert-success {
    background-color: mix($success, $white, 10%);
    border-color: $success;
}

.alert-warning {
    background-color: mix($warning, $white, 10%);
    border-color: $warning;
}

.alert-danger {
    background-color: mix($danger, $white, 10%);
    border-color: $danger;
}

// Text utilities
.text-truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.small {
    font-size: $small-font-size;
}

.smaller {
    font-size: $smaller-font-size;
}

// Shape utilities
.ellipse {
    width: 8px;
    height: 8px;
    background-color: $gray;
    border-radius: 50%;
}

// Position utilities
.fixed-bottom-right {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: white;
    padding: spacer(4) 3rem;
    border-top: 1px solid $gray-light;
    transition: width $animationSpeed ease-in-out;
    width: calc(100% - $menuClosedWidth);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

// Spacing utilities
.icon-spacing {
    margin-right: spacer(2);
}

// Responsive utilities
.table-responsive {
    display: block;
    width: 100%;
    max-width: calc(100vw - $menuClosedWidth - (spacer(8) * 2));
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-bottom: spacer(4);

    body:has(.menu-checkbox:checked) & {
        max-width: calc(100vw - $menuOpenWidth - (spacer(8) * 2));
    }

    .has-sidebar & {
        max-width: calc(100vw - $menuClosedWidth - $sidebarWidth - (spacer(8) * 2));
    }

    body:has(.menu-checkbox:checked) .has-sidebar & {
        max-width: calc(100vw - $menuOpenWidth - $sidebarWidth - (spacer(8) * 2));
    }
}